/** @jsxImportSource react */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import clsxm from '@common/lib/clsxm';
import HomeLarge from '@sly/icons/react/HomeLarge';
import Location from '@sly/icons/react/Location';
import Search from '@sly/icons/react/Search';
import { arrayOf, bool, func, object, oneOf, string } from 'proptypes';
import React from 'react';

// todo
const Icon = ({ name }) => {
  switch (name) {
    case 'community-size-large':
      return <HomeLarge size='m' />;
    default:
      return <Location size='m' />;
  }
};

const groupSuggestions = (suggestions) =>
  suggestions.reduce((acc, curr) => {
    if (!acc[curr.resourceType]) acc[curr.resourceType] = [];
    acc[curr.resourceType].push(curr);
    return acc;
  }, {});

const GROUP_LABELS = {
  City: 'Locations',
  Zipcode: 'Locations',
  GoogleCity: 'Locations',
  Community: 'Communities',
  PartnerAgent: 'Agents',
};

const GROUP_ICONS = {
  City: 'location',
  Zipcode: 'location',
  GoogleCity: 'location',
  Community: 'community-size-large',
  PartnerAgent: 'user',
};

const GROUP_LIMITS = {
  City: 5,
  Zipcode: 5,
  GoogleCity: 5,
  Community: 3,
  PartnerAgent: 3,
};

const GROUPS_DISPLAY_TEXT = ['City', 'Zipcode'];

const SearchBox = ({
  value,
  onChange,
  onSelect,
  onFocus,
  isTextboxInFocus,
  onSearchButtonClick,
  onCurrentLocationClick,
  onBlur,
  placeholder,
  readOnly,
  suggestions,
  showSearchIcon,
  invalid,
  className,
  inputClassName,
  newSearchBox = false,
}) => {
  const gps = groupSuggestions(suggestions);

  return (
    <div
      className={clsxm('relative', className)}
      data-tp-id='gen-div-4735c3f6-0c58-4262-a1e4-65b845f18614'
    >
      <div
        className={clsxm('flex rounded bg-white', inputClassName)}
        data-tp-id='gen-div-cd6f5b97-89d1-4139-8b8c-f247b84560b7'
      >
        <input
          className={clsxm(
            'm-0 block h-11 w-full text-ellipsis rounded rounded-r-none border border-r-0 border-slate-lighter-90  p-s py-2 text-slate-lighter-20 outline-none',
            newSearchBox ? 'h-14 rounded-full px-6' : 'px-4'
          )}
          type='text'
          placeholder={placeholder}
          disabled={false}
          onFocus={onFocus}
          onBlur={onBlur}
          readOnly={readOnly}
          onChange={onChange}
          invalid={invalid}
          value={value}
          data-tp-id='gen-input-43ac410b-1f2a-46e6-86d5-d189b5a85d5b'
        />
        {showSearchIcon && (
          <button
            className={clsxm(
              'flex items-center justify-center rounded rounded-l-none border border-l-0 border-slate-lighter-90',
              newSearchBox && 'h-14 w-14 rounded-full'
            )}
            aria-label='search'
            data-tp-id='gen-button-e2b49cf4-5dd4-4351-ad97-b9e0e553e9d1'
          >
            <Search
              size={!newSearchBox ? 'm' : 'xl'}
              className={clsxm(
                !newSearchBox && 'mr-m text-viridian-base',
                newSearchBox &&
                  'mr-2 h-8 w-8 rounded-full bg-viridian-base p-2 text-white'
              )}
              onClick={onSearchButtonClick}
            />
          </button>
        )}
      </div>
      {isTextboxInFocus &&
        (onCurrentLocationClick || suggestions.length > 0) && (
          <div
            className={clsxm(
              'absolute left-0 right-0 z-[10002] border-[0.062rem] border-slate-lighter-90 bg-white shadow-sm',
              newSearchBox && 'overflow-hidden rounded-3xl shadow-subtle'
            )}
            data-tp-id='gen-div-15a48b96-4d33-423e-91aa-d766f59275e3'
          >
            {/*
            use mouseDown instead of onClick as the onClick which is triggered after mouse button is release will trigger blur of textbox
            that will by the time hide the suggestions dropdown
          */}
            {onCurrentLocationClick && !suggestions.length && (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                className='font-b-s w-full cursor-pointer py-s pl-m hover:bg-viridian-lighter-90'
                onMouseDown={onCurrentLocationClick}
                data-tp-id='gen-div-8310632e-d23d-46e7-a01a-17159f21668d'
              >
                Current location
              </div>
            )}
            {Object.keys(gps).map((k) => (
              <div
                className='border-b pt-m'
                key={k}
                data-tp-id='gen-div-33e540f8-d9d2-499e-8d85-78591992f360'
              >
                <div
                  className='font-b-s px-m text-slate-lighter-40'
                  icon={GROUP_ICONS[k]}
                  data-tp-id='gen-div-212b8230-74d0-4787-9ed8-21a6c64d67e9'
                >
                  <div
                    className='flex items-center gap-xs'
                    data-tp-id='gen-div-296cb86c-bb6e-4f42-a99e-b72946fea54a'
                  >
                    <Icon
                      name={GROUP_ICONS[k]}
                      data-tp-id='gen-Icon-4d1be6a6-9e7e-4724-9157-6456962506dc'
                    />
                    {GROUP_LABELS[k]}
                  </div>
                </div>
                {gps[k].slice(0, GROUP_LIMITS[k]).map((suggestion) => (
                  <div
                    className={clsxm(
                      'font-b-s w-full cursor-pointer py-s pl-xxl text-slate-lighter-20 hover:bg-viridian-lighter-90',
                      suggestion.active ? 'bg-gray-600' : 'bg-white'
                    )}
                    onMouseDown={() => onSelect(suggestion)}
                    key={suggestion.id}
                    data-tp-id='gen-div-d031479d-322d-48aa-9e82-0113fe81c7af'
                  >
                    <div
                      className='mr-xxl inline'
                      data-tp-id='gen-div-00718741-2cf7-4107-8bcc-389e67e8451f'
                    >
                      {GROUPS_DISPLAY_TEXT.includes(suggestion.resourceType)
                        ? suggestion.displayText
                        : suggestion.name}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

SearchBox.propTypes = {
  layout: oneOf(['header', 'homeHero']),
  value: string.isRequired,
  onChange: func.isRequired,
  onSelect: func.isRequired,
  onSearchButtonClick: func,
  onFocus: func,
  isTextboxInFocus: bool,
  onCurrentLocationClick: func,
  onBlur: func,
  onKeyDown: func,
  placeholder: string,
  readOnly: bool,
  suggestions: arrayOf(object).isRequired,
  showSearchIcon: bool,
  type: string,
  invalid: bool,
};

SearchBox.defaultProps = {
  layout: 'header',
  placeholder: 'Search by city, state, zip',
  value: '',
  defaultValue: '',
  suggestions: [],
  showSearchIcon: true,
  type: 'text',
};

export default SearchBox;
